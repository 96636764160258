const model = {
  old_password: '',
  new_password: '',
  confirm_password: '',
};

const form = [
  {
    type: 'password',
    name: 'old_password',
    label: 'Current password',
    placeholder: 'Your current password',
    component: 'el-input',
    required: true,
    is_password: true,
  },
  {
    type: 'password',
    name: 'new_password',
    label: 'New password',
    placeholder: 'Your new password',
    component: 'el-input',
    required: true,
    is_password: true,
  },
  {
    type: 'password',
    name: 'confirm_password',
    label: 'Confirm password',
    placeholder: 'Confirm your new password',
    component: 'el-input',
    required: true,
    is_password: true,
  },
];

const rules = {
  old_password: [
    {
      type: 'required',
      message: 'Please enter your current password',
    },
    {
      type: 'minLength',
      minLength: 8,
    },
  ],
  new_password: [
    {
      type: 'required',
      message: 'Please enter your new password',
    },
    {
      type: 'minLength',
      minLength: 8,
    },
  ],
  confirm_password: [
    {
      type: 'required',
      message: 'Please confirm your new password',
    },
    {
      type: 'confirm',
      confirmField: 'new_password',
      message: 'The passwords do not match',
    },
  ],
};

export { model, form, rules };
